import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'
import Image from 'gatsby-image'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1, H2, H3 } from '../../components/Headings'
import Container from '../../components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import Hero from '../../components/Hero'
import jingleAudio from '../../../static/jingle.mp3'
import useSound from 'use-sound'

const JingleAudioPlayer = ({image}) => {
    const [play, { stop, isPlaying }] = useSound(jingleAudio)

    const handleClick = () => isPlaying ? stop() : play()

    return (
        <div tw="flex items-center">
            <FontAwesomeIcon icon={isPlaying ? faPauseCircle : faPlayCircle} size="3x" tw="text-yellow-600 mr-8 hover:(text-yellow-400) focus:(text-yellow-400) active:(text-yellow-500) cursor-pointer" onClick={handleClick} />
            <Image fixed={image.nodes[0].childImageSharp.fixed} imgStyle={{ objectFit: 'contain' }} />
        </div>
    )
}

const PageTemplate = ({ data }) => {
    const page = data.allWpPage.edges[0].node
    const {
        title, content, featuredImage,
        cookieTheCop: {
            heroHeadline,
            heroParagraph
        }
    } = page

    return (
        <>
            <SEO />
            <Layout>
                <Hero
                    css={['background: rgba(255,255,255,0.85);']}
                    background={featuredImage.node.localFile.childImageSharp.fixed}
                >
                    <div tw="py-16 md:(w-1/2 py-32)">
                        <p tw="text-5xl font-bold">{heroHeadline}</p>
                        <p tw="text-lg text-gray-800 leading-loose">{heroParagraph}</p>
                    </div>
                </Hero>
                <section aria-label="">
                    <Container tw="py-16">
                        <JingleAudioPlayer image={data.lookieLookie} />
                        <div tw="prose max-w-full" dangerouslySetInnerHTML={{ __html: content }}>
                            {/* {contentParser({ content: content }, { wordPressUrl: `http://wp-qa.cookspest.alloy.services/`, uploadsUrl: `http://wp-qa.cookspest.alloy.services/app/uploads/` })} */}
                        </div>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    lookieLookie: allFile(filter: {relativePath: {eq: "lookie-lookie.png"}}) {
        nodes {
            childImageSharp {
                fixed(width: 931) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
    allWpPage(filter: {id: {eq: $id}}) {
        edges {
          node {
            title
            content
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fixed(width: 1440) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
            }
            cookieTheCop {
                heroHeadline,
                heroParagraph
            }
          }
        }
    }
  }
`